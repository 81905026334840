const white = '#fff'
const gray = {
  200: '#ADBAC7',
  300: '#4a5568',
  400: '#cbd5e0',
  500: '#B1A296',
  600: '#1C2128',
  700: '#4a5568',
  800: '#22272E',
  900: '#1C2128'
}
const indigo = {
  100: '#ebf4ff',
  300: '#a3bffa',
  500: '#347D39',
  600: '#539BF5',
  800: '#434190'
}
const purple = {
  100: '#faf5ff',
  300: '#d6bcfa',
  500: '#9f7aea',
  600: '#805ad5',
  800: '#553c9a'
}
const green = {
  100: '#f0fff4',
  500: '#48bb78'
}
const red = {
  100: '#fff5f5',
  500: '#f56565'
}

//Primary
const alpha = {
  alphaLighter: indigo[100],
  alphaLight: indigo[300],
  alpha: indigo[500],
  alphaDark: indigo[600],
  alphaDarker: indigo[800]
}

// Secondary
const beta = {
  betaLighter: purple[100],
  betaLight: purple[300],
  beta: purple[500],
  betaDark: purple[600],
  betaDarker: purple[800]
}

// Reserved
const gamma = {}

// Reserved
const psi = {}

// Neutral
const omega = {
  omegaLighter: gray[200],
  omegaLight: gray[300],
  omega: gray[500],
  omegaDark: gray[600],
  omegaDarker: gray[800]
}

/*
1. create const with 100...
 */
const ramiColor = {
  100: '#22272E',
  101: '#263549',
  102: '#4A87D4',
  103: '#2D333B',


  300: '#a3bffa',
  500: '#347D39',
  600: '#539BF5',
  800: '#434190'
}

const rami = {
  ramiLighter: ramiColor[100],
  tagBg: ramiColor[101],
  tagColor: ramiColor[102],
  ramiLight: ramiColor[300],
  ramiOmega: ramiColor[500],
  ramiDark: ramiColor[600],
  ramiDarker: ramiColor[800]
}

export default {
  text: white,
  article: white,
  heading: rami[103],

  ...alpha,
  ...beta,
  ...gamma,
  ...psi,
  ...omega,
  ...rami,

  successLight: green[100],
  success: green[500],
  errorLight: red[100],
  error: red[500],

  white: white,
  background: '#5D5C61',
  contentBg: '#557A95',
  headerBg: '#948E94',
  footerBg: '#948E94',

  mute: '#22272E',
  highlight: gray[200],

  modes: {
    dark: {

      ramiLighter: rami.ramiLighter,
      tagBg: rami.tagBg,
      tagColor: rami.tagColor,
      // Reverse alpha
      alphaLighter: alpha.alphaDarker,
      alphaLight: alpha.alphaDark,
      alphaDark: alpha.alphaLight,
      alphaDarker: alpha.alphaLighter,

      // Reverse beta
      betaLighter: beta.betaDarker,
      betaLight: beta.betaDark,
      betaDark: beta.betaLight,
      betaDarker: beta.betaLighter,

      // Reverse omega
      omegaLighter: omega.omegaDarker,
      omegaLight: '#1C2128',
      omegaDark: '#A3AEB8',
      omegaDarker: omega.omegaLighter,

      text: gray[400],
      article: '#A3AEB8',
      heading: gray[200],


      background: gray[900],
      contentBg: ramiColor[100],
      headerBg: ramiColor[103],
      footerBg: gray[800],

      mute: gray[600]
    }
  }
}
